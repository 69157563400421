<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h6 class="card-title mb-3 text-capitalize">Your Workspaces</h6> -->
            <!-- <div class="row flex-grow">
              <div class="col-sm-6 col-md-3 grid-margin stretch-card" v-for="n in 8" :key="n">
                <div class="card">
                  <div class="card-body d-flex justify-content-between">
                    <div class="">
                      <p class="h4 text-primary">1</p>
                      <p class="text-muted">Used Devices</p>
                    </div>
                    <div class="">
                      <FeatherIcon type="message-circle" size="3em" stroke="#5591EC" fill="#5591EC" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr> -->
            <div class="d-flex justify-content-between">
              <!-- <h6 class="card-title mb-3 text-capitalize text-muted">Workspaces</h6> -->
              <h6 class="card-title mb-3 text-capitalize">Your Workspaces</h6>
              <button class="btn btn-primary" @click="showModal = true">Create workspace</button>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Workspace</th>
                    <th>Status</th>
                    <th>Plan</th>
                    <th>Price Plan</th>
                    <!-- <th>Billing</th> -->
                    <th colspan="4">Stats</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(workspace, i) in workspaces" :key="i" @click="manage(workspace)" style="cursor: pointer">
                    <td>{{ workspace.name }}</td>
                    <td class="text-capitalize">
                      <Status :online="workspace.status == 'active'" />
                      <span class="ml-2">{{ workspace.status }}</span>
                      <p class="text-muted" style="font-size: .75em">Synced a few second ago</p>
                    </td>
                    <td>
                      <Status online />
                      <span class="ml-2">{{ workspace.products[0].name }}</span>
                      <p class="text-muted" style="font-size: .75em">{{ moment(workspace.created_at).fromNow() }}</p>
                    </td>
                    <td>
                      <span class="ml-2">{{ workspace.products[0].is_postpaid ? 'Post Paid':'Pre Paid' }}</span>
                    </td>
                    <!-- <td>
                      <div class="d-flex justify-content-between mb-2">
                        <span>10%</span>
                        <span class="text-muted" style="font-size: .75em">Apr 2, 2021 - May 2, 2021</span>
                      </div>
                      <b-progress height="2px" :value="10"></b-progress>
                    </td> -->
                    <td>
                      <p class="text-muted" style="font-size: .75em">Total Ticket</p>
                      <p>
                        <el-skeleton v-if="workspace.stats_loading" :rows="1" animated />
                        <span v-else>{{ workspace.stats.total_ticket }}/{{ workspace.products[0].limit.ticket }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="text-muted" style="font-size: .75em">Open Ticket</p>
                      <p>
                        <el-skeleton v-if="workspace.stats_loading" :rows="1" animated />
                        <span v-else>{{ workspace.stats.open_ticket }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="text-muted" style="font-size: .75em">Queue</p>
                      <p>
                        <el-skeleton v-if="workspace.stats_loading" :rows="1" animated />
                        <span v-else>{{ workspace.stats.total_queue }}/1000</span>
                      </p>
                    </td>
                    <td>
                      <b-dropdown v-if="['idle', 'active'].includes(workspace.status)" text="Actions" variant="outline-primary" class="m-md-2">
                        <b-dropdown-item @click.stop="manage(workspace)">Manage Workspace</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click.stop="editWorkspace(workspace)">Edit</b-dropdown-item>
                        <template v-if="workspace.qrcode">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click.stop="() => {}">Chat with Workspace</b-dropdown-item>
                          <img style="border-radius: 0% !important;" :src="workspace.qrcode"/>
                        </template>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="!workspaces.length" description="No workspaces"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" title="Create New Workspace" hide-footer>
      <b-form @submit.prevent="createWorkspace" @reset="closeModal">
        <b-form-group
          id="ws-name-group"
          label="Workspace Name:"
          label-for="ws-name">
          <b-form-input
            id="ws-name"
            v-model="form.name"
            type="text"
            placeholder="Enter workspace name"
            required />
        </b-form-group>

        <b-form-group
          id="ws-product-group"
          label="Product:"
          label-for="ws-product">
          <b-form-select
            id="ws-product"
            v-model="form.ws_product_id"
            :options="product"
            required />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>

    <terms-condition :show="showModalTerms"/>

    <b-modal v-model="showModalEdit" title="Edit Workspace" hide-footer>
      <b-form @submit.prevent="saveEdit" @reset="closeModal">
        <b-form-group
          id="ws-name-group"
          label="Workspace Name:"
          label-for="ws-name">
          <b-form-input
            id="ws-name"
            v-model="form.name"
            type="text"
            placeholder="Enter workspace name"
            required />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { flatMap, isEmpty, uniq } from 'lodash';
import moment from 'moment';
import workspacesAPI from '@/api/workspaces';
import wsProductAPI from '@/api/wsProducts';
import analyticApi from '@/api/analytics';
import popupErrorMessages from '../../library/popup-error-messages';
import TermsCondWappinNew from './auth/TermsCondWappinNew.vue';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'Workspaces',
  metaInfo: {
    title: 'Workspaces',
  },
  data() {
    return {
      showModal: false,
      showModalEdit: false,
      form: {
        name: '',
        ws_product_id: '',
      },
      editedId: '',
      product: [],
      need_approve_new_wappin: false,
      showModalTerms: false,
      is_accept_terms: false,
      loaderStack: 0,
      loader: null,
    };
  },
  computed: {
    workspaces() {
      return this.$store.state.workspace.workspaces;
    },
    userMemberWorkspaceIds() {
      const is_agent = !isEmpty(this.$store.state.auth.userProfile.agents[0]);
      const { is_pic } = this.$store.state.auth.userProfile;
      if (is_agent && !is_pic) {
        return this.$store.state.auth.userProfile.agents.map((v) => v.workspace_id);
      }
      return uniq(flatMap(this.$store.state.auth.userProfile.members.map((v) => v.workspaces_id)));
    },
  },
  mounted() {
    this.$store.dispatch('workspace/loadWorkspaces', {
      workspace_ids: this.userMemberWorkspaceIds,
    })
      .then((res) => {
        if (res.data.rows.length === 0) {
          this.showModal = true;
        }
        this.need_approve_new_wappin = false;
        // Load workspaces stats
        this.loadStats();
      }).catch((err) => {
        if (err === 'need_approve_new_wappin') {
          this.need_approve_new_wappin = true;
          this.showModalTerms = true;
        }
      });
    this.loadProduct();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    loadWorkspaces() {
      this.$store.dispatch('workspace/loadWorkspaces', {
        workspace_ids: this.userMemberWorkspaceIds,
      }).then(async (res) => {
        if (res.data.rows.length === 0) {
          this.showModal = true;
        }
        this.need_approve_new_wappin = false;
        // Load workspaces stats
        this.loadStats();
      }).catch((err) => {
        if (err === 'need_approve_new_wappin') {
          this.need_approve_new_wappin = true;
          this.showModalTerms = true;
        }
      });
    },
    loadStats() {
      this.workspaces.forEach((value, index) => {
        analyticApi.workspaceStats({ workspace_id: value._id })
          .then((result) => {
            this.workspaces[index].stats = result.data;
            this.workspaces[index].stats_loading = false;
          });
      });
    },
    manage(workspace) {
      if (['idle', 'active'].includes(workspace.status)) {
        this.$store.dispatch('workspace/setActiveWorkspace', workspace)
          .then(() => {
            this.checkWorkspaceBalance(workspace._id);
            this.$router.push('/dashboard');
            // this.$router.push('/conversations');
          });
      }
    },
    async checkWorkspaceBalance(id) {
      const response = await workspacesAPI.check_balance({
        id,
      });
      await popupErrorMessages(response);
      this.$store.dispatch('workspace/setWorkspaceBalance', response.data.balance);
    },
    clearForm() {
      this.form.name = '';
      this.form.ws_product_id = '';
    },
    closeModal() {
      this.showModal = false;
      this.showModalEdit = false;
      this.clearForm();
    },
    async loadProduct() {
      const loader = this.$loading.show();
      await wsProductAPI.getList()
        .then((res) => {
          this.product = res.data.rows.map((item) => ({
            text: item.name,
            value: item._id,
          }));
        });
      loader.hide();
    },
    async createWorkspace() {
      const loader = this.$loading.show();
      await workspacesAPI.create(this.form)
        .then(async () => {
          // await this.$store.dispatch('ui/alertSuccess', 'Data berhasil ditambahkan');
          // this.showAlert();
          this.$message({
            message: this.$t('workspace.success.add'),
            type: 'success',
          });
          await this.$store.dispatch('workspace/loadWorkspaces', {
            workspace_ids: this.userMemberWorkspaceIds,
          });
          this.loadStats();
          this.closeModal();
        });
      loader.hide();
    },
    editWorkspace(workspace) {
      this.editedId = workspace._id;
      this.form.name = workspace.name;

      this.showModalEdit = true;
    },
    async saveEdit() {
      const loader = this.$loading.show();
      await workspacesAPI.update(this.editedId, this.form)
        .then(async () => {
          // await this.$store.dispatch('ui/alertSuccess', 'Data berhasil diubah');
          // this.showAlert();
          await this.$store.dispatch('workspace/loadWorkspaces', {
            workspace_ids: this.userMemberWorkspaceIds,
          });
          this.loadStats();
          this.closeModal();
        });
      loader.hide();
    },
    handleClose() {},
    handleShowQR(id) {
      const viewer = this.$refs[`viewer_${id}`].$viewer;
      viewer.show();
    },
  },
  components: {
    Status: () => import('../components/Status.vue'),
    TermsCondition: TermsCondWappinNew,
    // Viewer,
  },
};
</script>
